<template>
  <div>
    <v-card
      class="app-invoice-editable"
    >
      <v-card-actions>
        <v-toolbar
          class="primary"
          short
        >
          <v-toolbar-title style="color:white;">
            Property Adjustments
          </v-toolbar-title>
        </v-toolbar>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          small
          outlined
          @click="regresarListado"
        >
          Return to List
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row
            no-gutters
          >
            <!-- Renglon Base Property -->
            <v-col
              cols="12"
              md="4"
            >
              <v-container>
                <v-row dense>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label
                      class="font-weight-bold text-center"
                      dense
                      hide-details="auto"
                    >
                      Basics
                    </label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <label
                      class="font-weight-bold text-center"
                      dense
                      hide-details="auto"
                    >
                      Subject
                    </label>
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <label
                      dense
                      hide-details="auto"
                    >
                      Image Placeholder
                    </label>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      Address
                    </label>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="7"
                  >
                    <v-text-field
                      v-model="propertyData.propData.address"
                      v-uppercase
                      outlined
                      dense
                      readonly
                      placeholder="Home Address"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      Sold Date
                    </label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propData.soldDate"
                      v-uppercase
                      outlined
                      dense
                      readonly
                      placeholder="Sold Date"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      Sold Price
                    </label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      Unadj. ARV
                    </label>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propData.listPrice"
                      v-uppercase
                      outlined
                      dense
                      readonly
                      placeholder="List Price"
                      autocomplete="off"
                      hide-details="auto"
                      class="text-h6 centered-input"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      SqrFt
                    </label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propData.propertySqrFeet"
                      v-uppercase
                      outlined
                      dense
                      readonly
                      placeholder="Sqft"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      SqrFt Price
                    </label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      Unadj. Sqr Price
                    </label>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propData.propertySqrPrice"
                      v-uppercase
                      outlined
                      dense
                      readonly
                      placeholder="Sqft Price"
                      autocomplete="off"
                      hide-details="auto"
                      class="text-h6 centered-input"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      Year Build
                    </label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propData.propertyYearBuilt"
                      v-uppercase
                      outlined
                      dense
                      readonly
                      placeholder="Year Built"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <p class="font-weight-bold text-center">
                      Feature Values
                    </p>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <p class="font-weight-bold text-center">
                      Units
                    </p>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      Bedrooms
                    </label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propData.propertyBedsValue"
                      v-uppercase
                      outlined
                      dense
                      placeholder="$10,000"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="propertyData.propData.propertyBeds"
                      v-uppercase
                      outlined
                      dense
                      readonly
                      placeholder="1"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      Baths
                    </label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propData.propertyBathsValue"
                      v-uppercase
                      outlined
                      dense
                      placeholder="$5,000"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="propertyData.propData.propertyBaths"
                      v-uppercase
                      outlined
                      dense
                      readonly
                      placeholder="1"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      Garage
                    </label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propData.compGarageValue"
                      v-uppercase
                      outlined
                      dense
                      placeholder="$7,000"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="propertyData.propData.compGarage"
                      v-uppercase
                      outlined
                      dense
                      readonly
                      placeholder="1"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      Carport
                    </label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propData.compCarportValue"
                      v-uppercase
                      outlined
                      dense
                      placeholder="$2,500"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="propertyData.propData.compCarport"
                      v-uppercase
                      outlined
                      dense
                      readonly
                      placeholder="1"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      Lot Size
                    </label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propData.propertyLotSizeValue"
                      v-uppercase
                      outlined
                      dense
                      placeholder="$1,500"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="propertyData.propData.propertyLotSize02"
                      v-uppercase
                      outlined
                      dense
                      readonly
                      placeholder="354"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      Basement
                    </label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propData.compBasementValue"
                      v-uppercase
                      outlined
                      dense
                      placeholder="$3,500"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="propertyData.propData.compBasementSize"
                      v-uppercase
                      outlined
                      dense
                      readonly
                      placeholder="120"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      Has Pool
                    </label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propData.compPoolValue"
                      v-uppercase
                      outlined
                      dense
                      placeholder="$20,000"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                    class="d-flex justify-center"
                  >
                    <v-checkbox
                      v-model="propertyData.propData.compHaspool"
                      dense
                    >
                    </v-checkbox>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <label
                      class="font-small-label"
                      hide-details="auto"
                    >
                      Has View
                    </label>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propData.compViewValue"
                      v-uppercase
                      outlined
                      dense
                      placeholder="$15,000"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                    class="d-flex justify-center"
                  >
                    <v-checkbox
                      v-model="propertyData.propData.compHasview"
                      dense
                    >
                    </v-checkbox>
                  </v-col>

                  <v-col
                    cols="12"
                    md="10"
                  >
                    <a
                      href="#"
                      class="text-decoration-none"
                      style="color: #81D4FA;"
                    >
                      Add Features
                    </a>
                  </v-col>

                  <v-col
                    cols="12"
                    md="10"
                  >
                    <p class="font-weight-bold">
                      Adjustment Subtotals
                    </p>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <p class="font-weight-bold">
                      Adjusted $/Sqft:
                    </p>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propData.adjustedSqrPrice"
                      v-uppercase
                      outlined
                      dense
                      readonly
                      placeholder="124"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <p class="font-weight-bold">
                      Adjusted ARV $:
                    </p>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propData.adjustedArvPrice"
                      v-uppercase
                      outlined
                      dense
                      readonly
                      placeholder="124000"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>

            <v-col
              cols="12"
              md="8"
            >
              <v-data-iterator
                :items="propertyData.propData.filterListProperties"
                :items-per-page.sync="itemsPerPage"
                :page.sync="page"
                hide-default-footer
              >
                <template v-slot:default="props">
                  <v-row dense>
                    <v-col
                      v-for="(item, key) in props.items"
                      :key="key"
                      cols="12"
                      md="4"
                    >
                      <v-row dense>
                        <v-col
                          cols="12"
                          md="10"
                        >
                          <v-checkbox
                            v-model="item.isSelected"
                            label="Comparable"
                            dense
                            hide-details="auto"
                          ></v-checkbox>
                        </v-col>

                        <v-col
                          cols="12"
                          md="10"
                        >
                          <label
                            dense
                            hide-details="auto"
                          >
                            Image Placeholder
                          </label>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="10"
                        >
                          <v-text-field
                            v-model="item.address"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="Home Address"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                          class="d-flex justify-center"
                        >
                          <v-chip
                            small
                            :color="obligatorioColor[item.isSoldSale]"
                            :class="`${obligatorioColor[item.isSoldSale]}--text`"
                            class="v-chip-light-bg"
                          >
                            {{ obliga[item.isSoldSale] }}
                          </v-chip>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="item.soldDate"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="Sold Date"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="item.listPrice"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="List Price"
                            autocomplete="off"
                            hide-details="auto"
                            class="centered-input"
                          >
                          </v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="item.propertySqrFeet"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="Sqft"
                            autocomplete="off"
                            hide-details="auto"
                          >
                            <v-icon
                              slot="append"
                              :color="iconColor[item.isSqrFeet]"
                            >
                              {{ iconTexto[item.isSqrFeet] }}
                            </v-icon>
                          </v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="item.propertySqrPrice"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="SqFt Price"
                            autocomplete="off"
                            hide-details="auto"
                          >
                            <v-icon
                              slot="append"
                              :color="iconColor[item.isSqrPrice]"
                            >
                              {{ iconTexto[item.isSqrPrice] }}
                            </v-icon>
                          </v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="item.propertyYearBuilt"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="Year Built"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                          <p class="font-weight-bold text-center">
                            Units
                          </p>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <p class="font-weight-bold text-center">
                            Adjustment
                          </p>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-text-field
                            v-model="item.propertyBedsDiff"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="1"
                            autocomplete="off"
                            hide-details="auto"
                          >
                            <v-icon
                              slot="append"
                              :color="iconColor[item.isBeds]"
                              size="16"
                            >
                              {{ iconTexto[item.isBeds] }}
                            </v-icon>
                          </v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="item.propertyBedsValue"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="$10,000"
                            autocomplete="off"
                            hide-details="auto"
                          >
                          </v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-text-field
                            v-model="item.propertyBathsDiff"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="1"
                            autocomplete="off"
                            hide-details="auto"
                          >
                            <v-icon
                              slot="append"
                              :color="iconColor[item.isBaths]"
                              size="18"
                            >
                              {{ iconTexto[item.isBaths] }}
                            </v-icon>
                          </v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="item.propertyBathsValue"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="$5,000"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-text-field
                            v-model="item.propertyGarageDiff"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="1"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="item.propertyGarageValue"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="$7,000"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-text-field
                            v-model="item.propertyCarportDiff"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="1"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="item.propertyCarportValue"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="$2,500"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-text-field
                            v-model="item.propertyLotSizeDiff"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="354"
                            autocomplete="off"
                            hide-details="auto"
                          >
                            <v-icon
                              slot="append"
                              :color="iconColor[item.isLotSize]"
                              size="16"
                            >
                              {{ iconTexto[item.isLotSize] }}
                            </v-icon>
                          </v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="item.propertyLotSizeValue"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="$1,500"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-text-field
                            v-model="item.propertyBasementDiff"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="120"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="item.propertyBasementValue"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="$3,500"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-text-field
                            v-model="item.propertyPoolDiff"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="2"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="item.propertyPoolValue"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="$20,000"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                          <v-text-field
                            v-model="item.propertyViewDiff"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="1"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="item.propertyViewValue"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="$15,000"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="10"
                        >
                          <a
                            href="#"
                            class="text-decoration-none"
                            style="color: #81D4FA;"
                          >
                            Add Features
                          </a>
                        </v-col>

                        <v-col
                          cols="12"
                          md="10"
                        >
                          <p>&nbsp;</p>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="item.adjustedSqrPrice"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="124"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                        >
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="item.adjustedArvPrice"
                            v-uppercase
                            outlined
                            dense
                            readonly
                            placeholder="124000"
                            autocomplete="off"
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </template>

                <template v-slot:footer>
                  <v-row
                    class="mt-2"
                    align="center"
                    justify="center"
                  >
                    <v-spacer></v-spacer>

                    <span class="mr-4 grey--text">
                      Page {{ page }} of {{ numberOfPages }}
                    </span>
                    <v-btn
                      fab
                      small
                      dark
                      color="primary"
                      class="mr-1"
                      @click="formerPage"
                    >
                      <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
                    </v-btn>
                    <v-btn
                      fab
                      small
                      dark
                      color="primary"
                      class="mr-1"
                      @click="nextPage"
                    >
                      <v-icon>{{ icons.mdiChevronRight }}</v-icon>
                    </v-btn>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </div>
</template>

<script>
import {
  mdiSendOutline, mdiCurrencyUsd, mdiChevronRight, mdiChevronLeft, mdiArrowUpBold, mdiArrowDownBold, mdiEqual,
} from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// import EventBus from '@/views/event-bus'
// import axios from '@axios'
import propcompStore from '../propcompStore'

export default {
  data: () => ({
    page: 1,
    itemsPerPage: 3,
    itemsPerPageArray: [1, 2, 3],
    iconArray: [],
  }),
  computed: {
    numberOfPages() {
      return Math.ceil(this.propertyData.propData.filterListProperties.length / this.itemsPerPage)
    },
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
  },

  // methods: {
  //   async onViewDetailsClick(id) {
  //     this.id = id
  //     console.log(this.id)
  //     let result = null

  //     // eslint-disable-next-line no-await-in-loop
  //     try {
  //       // eslint-disable-next-line no-await-in-loop
  //       const response = await axios.get(`/api/propertycomparison/leerregistro?id=${id}`)
  //       result = response.data
  //       console.log(result)
  //       console.log(this.items)
  //     } catch (error) {
  //       console.log(error)
  //     }

  //     if (result) {
  //       this.propertyData = result
  //       const imageJSON = [
  //         {
  //           src: this.propertyData.image01,
  //         },
  //         {
  //           src: this.propertyData.image02,
  //         },
  //         {
  //           src: this.propertyData.image03,
  //         },
  //       ]
  //       console.log(imageJSON)
  //       this.items = imageJSON
  //     } else {
  //       console.log(result)
  //     }
  //   },
  // },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    const isInvoiceSendSidebarActive = ref(false)
    const isAddPaymentSidebarActive = ref(false)

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, propcompStore)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const propertyData = ref({})
    const page = 1
    const carousel = 0 // like this
    const iconColor = ['error', 'success', 'warning']
    const iconTexto = [mdiArrowDownBold, mdiArrowUpBold, mdiEqual]

    store
      .dispatch('app-invoice/fetchPropComparisonAdjustment', { id: router.currentRoute.params.id })
      .then(response => {
        propertyData.value = {
          propData: response.data,
        }

        console.log(propertyData)

        return response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          propertyData.value = undefined
        }
      })

    const regresarListado = () => {
      router.go(-1)
    }

    const obligatorioColor = {
      true: 'success',
      false: 'error',
    }

    return {
      propertyData,
      page,
      carousel,
      regresarListado,

      // drawer
      isInvoiceSendSidebarActive,
      isAddPaymentSidebarActive,
      obligatorioColor,
      obliga: {
        true: 'On Sale',
        false: 'Sold',
      },

      // Icons
      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
        mdiChevronRight,
        mdiChevronLeft,
      },
      iconColor,
      iconTexto,
    }
  },
}
</script>

<style lang="scss" >

.centered-input input {
  text-align: center;
}

.font-small-label {
  font-size: 11px !important;
  font-weight: bold !important;
}

</style>
